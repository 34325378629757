@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    background-color: #F3F3F3;
    margin: 0;
}

.hidden {
    display: none !important;
}

.container .ql-editor {
    width: 8.5in;
    min-height: 11in;
    padding: 1in;
    margin: 1rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
}

.container .ql-container.ql-snow {
    border: none;
    display: flex;
    justify-content: center;
}

.container .ql-toolbar.ql-snow {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

@page {
    margin: 1in;
}

@media print {
    body {
        background-color: none;
    }

    .container .ql-editor {
        width: 6.5in;
        height: 9in;
        padding: 0;
        margin: 0;
        box-shadow: none;
        align-self: flex-start;
    }

    .container .ql-toolbar.ql-snow {
        display: none;
    }
}

.headingBar {
    background-color: #ffff;
}

.headingBar h1 {
    font-family: sans-serif;
}

#nameInput {
    font-size: larger;
    margin: 1em;
    border: none;
    padding: 0.25em;
}

#nameInput:hover {
    border: #c9c9c9 .5px solid;
    border-radius: .2em;
}


/* Set dropdown font-families */
#toolbar .ql-font span[data-label="Arial"]::before {
    font-family: "Arial";
}
#toolbar .ql-font span[data-label="Comic Sans"]::before {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
}
#toolbar .ql-font span[data-label="Roboto"]::before {
    font-family: 'Roboto', sans-serif;
}

.ql-font-arial {
    font-family: "Arial";
}

.ql-font-roboto {
    font-family: 'Roboto', sans-serif;
}

.ql-font-comic-sans {
    font-family: "Comic Sans MS", "Comic Sans", cursive;
}